import React from 'react'
import MetaData from '../../components/MetaData'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../../components/PageWrapper'
import { Section, Title, Text } from '../../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Security = () => {
  const { t, ready } = useTranslation(['securityPolicy'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false


  return ready && (
    <>
      <MetaData page="default" />
      <PageWrapper footerDark>
        <Section bg={userDarkMode ? 'black' : 'light'}>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="hero">
                  {t('heroTitle')}
                </Title>
                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  <strong>{t('topPriority')}</strong>
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('dataIsSafe')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('accountProtection')}
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('dataIsEncrypted')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('everyReq')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('passwords')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('2fa')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('u2f')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('loginAttempt')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('2faRequired')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('failedLogin')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('lockdownLinks')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('algos')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('apiKeySecurity')}
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('apiAccounts')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('apiKeys')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('ssl')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('systemSecurity')}
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('lsIsHosted')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('multiTiredServer')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('penTesting')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('firewall')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('auditedSystems')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('dnsLevel')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('internalAuditing')}
                </Text>

                <Title className="mt-5" color={userDarkMode ? '#FAFAFA' : 'black'} variant="card">
                  {t('employeeSecurity')}
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('roleBased')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('strongVpn')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('thirdPartyAccounts')}
                </Text>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'} className="mb-3" variant="small">
                  {t('accountAuditing')}
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Security
